import './instagram-feed.scss';

class InstagramFeed {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-instagramfeed',
            sliderAttr: 'slider'
        };

        this.settings = Object.assign({}, settings, options);
        this.$instagramFeed = element;
        this.$instagramFeedSlider = this.$instagramFeed.querySelector('[' + this.settings.initAttr + '="' + this.settings.sliderAttr + '"]');
    }

    initialize () {
        if (this.$instagramFeedSlider !== null) {
            import('../../components/slider/slider').then(({ Slider }) => {
                this.initSlider(Slider);
            });
        }
    }

    initSlider (Slider) {
        const instagramFeedSlider = new Slider(this.$instagramFeedSlider, { // eslint-disable-line
            breakpoints: {
                768: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 4
                }
            },
            loop: true,
            slidesPerView: 1,
            initAttr: this.settings.initAttr
        });
    }
}

export { InstagramFeed };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const $instagramFeeds = $context.querySelectorAll('[data-instagramfeed="root"]');

    if ($instagramFeeds.length > 0) {
        $instagramFeeds.forEach((instagramFeed) => {
            const $instagramFeed = new InstagramFeed(instagramFeed);
            $instagramFeed.initialize();
        });
    }
});
